import React from 'react';
import { Helmet } from 'react-helmet';
const { IntlProvider } = require('react-intl');

const Layout = require('templates/Layout').default;

const config = require(`./config.${process.env.NODE_ENV || process.env.GATSBY_APP}.js`);

const messages = {
    en: require('languages/en.json')
};

const schemaOrgJSONLD = [
    {
        '@context': 'http://schema.org/',
        '@type': 'organization',
        url: config.BASE_URL,
        name: 'Spectro Finance, UAB',
        alternateName: 'Spectro Finance, UAB',
        logo: `${config.BASE_URL + '/logo.png'}`,
        address: [
            {
                '@type': 'PostalAddress',
                streetAddress: 'Vilniaus g. 31',
                addressLocality: 'Vilnius',
                addressCountry: 'LT',
                postalCode: 'LT-01402',
                contactType: 'product development'
            }
        ],
        contactPoint: [
            {
                '@type': 'ContactPoint',
                contactType: 'customer service',
                email: 'mailto:info@spectrofinance.lt',
                telephone: '+370 700 44940',
                areaServed: ['EN'],
                availableLanguage: ['English']
            }
        ]
    }
];

export const wrapPageElement = ({ element }) => (
    <IntlProvider locale="en" key="en" messages={messages['en']}>
        <Layout>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
            </Helmet>
            {element}
        </Layout>
    </IntlProvider>
);
