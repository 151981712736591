import React from 'react';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

const Layout = ({ children }) => (
	<div>
		<Header/>
		<div>{children}</div>
		<Footer/>
	</div>
);
export default Layout;