import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'gatsby';

import logo from 'assets/images/logo-footer.svg';

import style from './Footer.module.scss';

const translations = defineMessages({
	logo: {
		id: 'main.logo',
		defaultMessage: 'Spectro Finance logo'
	}
});

const Footer = () => {
	const { formatMessage } = useIntl();
	return (
		<footer className={style.footer}>
			<div className={style.container}>
				<Link to="/">
					<img
						src={logo}
						alt={formatMessage(translations.logo)}
						className={style.logo}
					/>
				</Link>
			</div>
		</footer>
	);
};

export default Footer;
