import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'gatsby';

import logo from 'assets/images/logo.svg';

import style from './Header.module.scss';

const translations = defineMessages({
	logo: {
		id: 'main.logo',
		defaultMessage: 'Spectro Finance logo'
	}
});

const Header = () => {
	const { formatMessage } = useIntl();
	return (
		<header className={style.container}>
			<div className={style.header}>
				<Link to="/">
					<img
						src={logo}
						alt={formatMessage(translations.logo)}
						className={style.logo}
					/>
				</Link>
			</div>
		</header>
	)
};

export default Header;